import * as Yup from 'yup';

export const PROFILE_HEALTH_PROFILE = () => {
    return Yup.object().shape({
        healthConditions: Yup.array()
            .of(Yup.string())
            .test('healthConditions-or-custom', 'Select at least one option.', function (value) {
                const { customHealthCondition } = this.parent;
                return (
                    (value && value.length > 0) || (customHealthCondition && customHealthCondition.trim().length > 0)
                );
            }),
        customHealthCondition: Yup.string().nullable(),
        allergiesConditions: Yup.array()
            .of(Yup.string())
            .test('allergiesConditions-or-custom', 'Select at least one option.', function (value) {
                const { customAllergyCondition } = this.parent;
                return (
                    (value && value.length > 0) || (customAllergyCondition && customAllergyCondition.trim().length > 0)
                );
            }),
        customAllergyCondition: Yup.string().nullable()
    });
};
